// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import L4 from "./xcbAMMUTg";
const L4Fonts = getFonts(L4);

const cycleOrder = ["jTgwisTp_"];

const serializationHash = "framer-KIfvK"

const variantClassNames = {jTgwisTp_: "framer-v-lpr39g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, DGoBoBfr6: tap ?? props.DGoBoBfr6} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DGoBoBfr6, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jTgwisTp_", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const dUta1SqFO1gbk8r2 = activeVariantCallback(async (...args) => {
if (DGoBoBfr6) {
const res = await DGoBoBfr6(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-lpr39g", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"jTgwisTp_"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><motion.div className={"framer-dq37co-container"} layoutDependency={layoutDependency} layoutId={"LMRqHxaUp-container"}><L4 dUta1SqFO={dUta1SqFO1gbk8r2} height={"100%"} id={"LMRqHxaUp"} layoutId={"LMRqHxaUp"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-KIfvK[data-border=\"true\"]::after, .framer-KIfvK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KIfvK.framer-11qi00l, .framer-KIfvK .framer-11qi00l { display: block; }", ".framer-KIfvK.framer-lpr39g { height: 316px; overflow: hidden; position: relative; width: 1320px; }", ".framer-KIfvK .framer-dq37co-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 316
 * @framerIntrinsicWidth 1320
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DGoBoBfr6":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerLJ9iLY2d1: React.ComponentType<Props> = withCSS(Component, css, "framer-KIfvK") as typeof Component;
export default FramerLJ9iLY2d1;

FramerLJ9iLY2d1.displayName = "sdss";

FramerLJ9iLY2d1.defaultProps = {height: 316, width: 1320};

addPropertyControls(FramerLJ9iLY2d1, {DGoBoBfr6: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerLJ9iLY2d1, [...L4Fonts])